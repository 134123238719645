<template>
  <div class="mb-6">
    <h2 v-if="!editMode" class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Create new Product
    </h2>
    <h2 v-else class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Edit Product
    </h2>
    <div
      class="flex flex-col w-full p-4 space-y-4 bg-white border border-gray-200 shadow-lg md:w-3/4 dark:bg-gray-700 dark:border-gray-500 md:p-8 rounded-xl">
      <div class="w-full">
        <label class="">
          <span class="label">Name</span>
          <input type="text" class="input" placeholder="Name" v-model="$v.product.name.$model" />
        </label>
      </div>
      <div class="w-full">
        <label class="">
          <span class="label">Price</span>
          <input type="number" class="input" placeholder="Price" v-model="$v.product.price.$model" />
        </label>
      </div>
      <div class="w-full">
        <label class="flex items-center space-x-4">
          <span class="select-none label">Has inventory</span>
          <input type="checkbox" v-model="$v.product.has_inventory.$model"
            class="text-primary-light rounded form-checkbox" />
        </label>
      </div>
      <div class="w-full">
        <label class="">
          <span class="label">Product belongs to which cafe</span>
          <select v-model="$v.product.cafe_id.$model" class="input">
            <option value="" selected>Select product's cafe</option>
            <option v-for="cafe in cafes" :key="cafe.id" :value="cafe.id">
              {{ cafe.name }}
            </option>
          </select>
        </label>
      </div>
      <div class="w-full">
        <label class="">
          <span class="label">Select product category</span>
          <select v-model="$v.product.category_id.$model" class="input">
            <option value="" selected>Select product's category</option>
            <option v-for="category in filteredCategories" :key="category.id" :value="category.id">
              {{ category.name }}
            </option>
          </select>
        </label>
      </div>
      <div class="flex justify-center w-full">
        <button class="btn w-36" :class="$v.$invalid ? 'bg-gray-600 text-white' : null"
          @click="editMode ? updateProduct() : saveProduct()">
          {{ editMode? "Update product": "Create product" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import isNil from "ramda/es/isNil";
export default {
  name: "CreateproductComponent",
  mixins: [validationMixin],
  data() {
    return {
      product: {
        name: "",
        price: "",
        cafe_id: "",
        category_id: "",
        has_inventory: false,
      },
    };
  },
  mounted() {
    this.loadCafes();
    this.loadCategories();
    if (this.editMode) {
      this.loadProduct();
    }
  },
  computed: {
    ...mapGetters({
      cafes: ["cafe/getCafes"],
      categories: ["category/getCategories"],
      prod: ["product/getProduct"],
    }),
    filteredCategories() {
      return this.product.cafe_id
        ? this.categories.filter((c) => c.cafe_id == this.product.cafe_id)
        : this.categories;
    },
    editMode() {
      return !isNil(this.$route.params) && !isNil(this.$route.params.nano_id);
    },
  },
  validations: {
    product: {
      name: {
        required,
      },
      price: {
        required,
      },
      category_id: {
        required,
      },
      cafe_id: {
        required,
      },
      has_inventory: {
        required,
      },
    },
  },
  methods: {
    loadCafes() {
      this.$store.dispatch("cafe/getCafes");
    },
    loadCategories() {
      this.$store.dispatch("category/getCategories");
    },
    loadProduct() {
      this.$store.dispatch("product/loadProduct", {
        nano_id: this.$route.params.nano_id,
      });
    },
    saveProduct() {
      if (!this.$v.$invalid) {
        this.$store.dispatch("product/storeProduct", { product: this.product });
      }
    },
    updateProduct() {
      if (!this.$v.$invalid) {
        this.$store.dispatch("product/updateProduct", {
          nano_id: this.$route.params.nano_id,
          product: this.product,
        });
      }
    },
  },
  watch: {
    prod: {
      handler() {
        if (!isNil(this.prod) && this.editMode) {
          this.product = {
            ...this.prod,
            category_id: this.prod.category ? this.prod.category.id : "",
            cafe_id: this.prod.cafe ? this.prod.cafe.id : "",
          };
        }
      },
      immediate: true,
    },
  },
};
</script>

<style>

</style>
